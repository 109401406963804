import React from "react"
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import { Link } from "gatsby";

const Contact: React.FC<{}> = () => {
  return (
    <Layout>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <h1 className="mb-4">Contact Info</h1>
      <h2>E-Mail</h2>
      <div className="my-2">mail@riksolo.com</div>

      <h2 className="mt-4">Social Media</h2>
      <ul className="my-2">
        {[
          {url: "https://twitter.com/rikberkelder", title: "Twitter"},
          {url: "https://dook.business/@RikSolo", title: "Mastodon"},
          {url: "https://instagram.com/rikberkelder", title: "Instagram"},
          {url: "https://github.com/rikberkelder", title: "Github"},
        ].map(item => {
          return <li><a href={item.url}>{item.title}</a></li>
        })}
      </ul>

      <div className="small mt-6 pt-6">
        <small><Link to="/impressum">Impressum</Link></small>
      </div>

    </Layout>
  );
};

export default Contact;
